













import { Component, Vue, Watch } from 'vue-property-decorator'
import NotFoundIcon from '@/general/components/icons/NotFound.vue'

@Component({
  components: {
    NotFoundIcon
  }
})
export default class Error extends Vue {
  get notFound() {
    return this.$store.state.notFound
  }

  @Watch('$route')
  watchRoute() {
    this.$store.commit('notFound', false)
  }
}
